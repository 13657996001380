body {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-optical-sizing: auto;
  padding: 0;
  margin: 0;
  background-image: url("./bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

html:focus-within {
  scroll-behavior: smooth;
}



.profile-photo {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 750px; /* Adjust as needed */
  margin-top: -300px; /* Adjust as needed */

}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  text-align: left;
  margin: 185px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 2.5rem;
  font-weight: 900;
  color: #fff;
  margin: 10px 0;
}

.title-name {
  flex: 1;

  font-size: 3.5rem;
  font-weight: 900;
  color: #000;
  background-color: #fff;
  padding: 0 10px;
  font-style: italic;
  /* background: linear-gradient(to top, rgb(22, 37, 251) 20%, #fff 85%);
  -webkit-background-clip: text;
  background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
}

.subtitle {
  font-size: 2.5rem;
  color: #fff;
  font-weight: 700;
  margin: 10px 0;
  flex: 1;

}

.about-me {
  font-size: 1.1rem;
  font-weight: 300;
  color: #fff;
  margin: 20px 0;
  line-height: 1.5;
  flex: 1;

}

.contactLinks {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 15px;
  margin: 15px 0;
}

.iconLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 7px;
  color: #fff;
  text-decoration: none;
  transition: all 213ms ease;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.iconLink:hover {
  transform: translate(0, -3px);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.75);
}

.navbar {
  margin: 0 auto; /* Center the navbar horizontally */
  display: flex;
  justify-content: center; /* Center the items in the navbar */
  align-items: center; /* Vertically center the items */
  gap: 15px;
  position: sticky;
  top: 20px;
  overflow: hidden;
  z-index: 1000;
  transition: all 213ms ease;
  width: 100%; /* Ensure the navbar spans the full width */
}

.navbar-bg {
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  padding-left: 20px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  display: flex; /* Make sure the background can align with navbar items */
  justify-content: center; /* Center the items inside navbar-bg */
}

.navbar a {
  text-decoration: none;
  color: #fff;
  display: block;
  font-size: 1.1rem;
  font-weight: 400;
  transition: all 213ms ease;
}

.navbar a:hover {
  transform: translate(0, -3px);
}

.section {
  margin: 40px 40px 100px 40px;
}

.sectionTitle {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 50px;
}

.skills {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .skills {
    justify-content: center;
  }
}

.skillCard {
  width: 75px;
  height: 75px;
  padding: 10px;
  cursor: pointer;
  border-radius: 7px;
  object-fit: contain;
  transition: all 213ms ease;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.skillCard:hover {
  transform: translate(0, -3px);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.75);
}

.projectGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
}

.card {
  color: #fff;
  max-height: fit-content;
  border-radius: 15px;
  transition: all 213ms ease;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.card:hover {
  background: rgba(255, 255, 255, 0.1);
}

.cardImage {
  width: 100%;
  height: 225px;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.cardContent {
  padding: 22px;
}

.cardTitle {
  margin-top: 0;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 2px;
}

.cardDescription {
  font-size: 0.875rem;
  font-weight: 400;
  color: #fff;
}

.cardTech {
  font-size: 0.875rem;
  color: #fff;
  font-weight: 400;
}

.featureList {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.featureItem {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 6px;
}

.featureIcon {
  margin-right: 6px;
}

.buttons {
  border-top: 1px solid #595959;
  display: flex;
  padding-top: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.button {
  display: inline-block;
  padding: 10px 12px;
  border-radius: 7px;
  background-color: #fff;
  text-transform: capitalize;
  color: #000;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.8rem;
  transition: all 213ms ease;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.button:hover {
  transform: translate(0, -3px);
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]::before,
[data-tooltip]::after {
  text-transform: none;
  font-size: 0.9em;
  line-height: 1;
  position: absolute;
  display: none;
  opacity: 0;
}

[data-tooltip]::before {
  content: "";
  border: 6px solid transparent;
  z-index: 101;
}

[data-tooltip]::after {
  content: attr(data-tooltip);
  text-align: center;
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 8px;
  border-radius: 7px;
  background: #fff;
  color: #000;
  z-index: 100;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.75);
}

[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
  display: block;
}

[data-tooltip=""]::before,
[data-tooltip=""]::after {
  display: none !important;
}

[data-tooltip]:not([data-flow])::before,
[data-tooltip][data-flow^="top"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #fff;
}

[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow^="top"]::after {
  bottom: calc(100% + 5px);
}

[data-tooltip]:not([data-flow])::before,
[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow^="top"]::before,
[data-tooltip][data-flow^="top"]::after {
  left: 50%;
  transform: translate(-50%, -0.4em);
}

[data-tooltip][data-flow^="bottom"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #fff;
}

[data-tooltip][data-flow^="bottom"]::after {
  top: calc(100% + 5px);
}

[data-tooltip][data-flow^="bottom"]::before,
[data-tooltip][data-flow^="bottom"]::after {
  left: 50%;
  transform: translate(-50%, 0.4em);
}

[data-tooltip][data-flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #fff;
  left: calc(0em - 5px);
  transform: translate(-0.5em, -50%);
}

[data-tooltip][data-flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-0.4em, -50%);
}

[data-tooltip][data-flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #fff;
  right: calc(0em - 7px);
  transform: translate(0.4em, -50%);
}

[data-tooltip][data-flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(0.5em, -50%);
}

@keyframes tooltip-vert {
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltip-horz {
  to {
    opacity: 1;
    transform: translate(0, -50%);
  }
}

[data-tooltip]:not([data-flow]):hover::before,
[data-tooltip]:not([data-flow]):hover::after,
[data-tooltip][data-flow^="top"]:hover::before,
[data-tooltip][data-flow^="top"]:hover::after,
[data-tooltip][data-flow^="bottom"]:hover::before,
[data-tooltip][data-flow^="bottom"]:hover::after {
  animation: tooltip-vert 0.3s ease-out forwards;
}

[data-tooltip][data-flow^="left"]:hover::before,
[data-tooltip][data-flow^="left"]:hover::after,
[data-tooltip][data-flow^="right"]:hover::before,
[data-tooltip][data-flow^="right"]:hover::after {
  animation: tooltip-horz 0.3s ease-out forwards;
}
